import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Curse from '../assets/curse.mp4';
import PlayerIcon from '../assets/PlayIcon.png';
import '../App.css';
import Footer from './Footer';

export default function PlayVideo() {
  const [showVideoPlayer, setShowVideoPlayer] = useState(true);
  const history = useHistory();
  const { hexPhoneNumber } = useParams();
  
  const handleEndedVideo = () => {
    setShowVideoPlayer(false);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch(`https://theonionringcurse.com/curse.php?v=${hexPhoneNumber}`, requestOptions)
      .then(response => {
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }
  return (
    <div className='app'>
      <div className='title videoText'>
        <p>YOU'VE BEEN CHOSEN.</p>
        <p>DON'T BE SCARED. PRESS PLAY.</p>
      </div>
      <div className='videoWrapper'>
        <div className='player-wrapper'>
          {showVideoPlayer ?
            <ReactPlayer
              className='react-player'
              url={Curse}
              onEnded={handleEndedVideo}
              light={true}
              controls={true}
              playing
              playIcon={< img src={PlayerIcon} alt='Play Icon' ></img >}
              width='100%'
              height='100%'
            /> 
            :
            <div className='answerItWrapper'>
              <div>
                ANSWER IT
              </div>
            </div>
          }
        </div >
      </div>
      <div className='row step paddingBottom50'>
        <button className='btn' onClick={() => { history.push('/'); }}>SPREAD THE CURSE</button>
      </div>
      <Footer />
    </div>
  );
}
