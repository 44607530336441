import React, { useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import '../App.css';
import Footer from './Footer';

export default function SpreadTheCurse() {
  const history = useHistory();
  const { phoneNumber } = useParams();
  const [copy, setCopySuccess] = useState('COPY LINK');
  const textAreaRef = useRef(null);
  const hexPhoneNumber = parseInt(phoneNumber).toString(16);

  const link = `https://theonionringcurse.com/${hexPhoneNumber}`;

  const copyToClipboard = (event) => {
    textAreaRef.current.select();
    document.execCommand('copy');
    event.target.focus();
    setCopySuccess('COPIED!');
  };

  return (
    <div className='app'>
      <div className='title shareContainer'>
        <p>STEP 1: COPY THIS CURSED LINK.</p>
      </div>
      <div className='row step'>
        <textarea readOnly className='btn textarea' ref={textAreaRef} value={link}>{link}</textarea>
        <button className='btn copyBtn' onClick={copyToClipboard}>{copy}</button>
      </div>
      <div className='title step'>
        <p>STEP 2: SEND THE LINK TO YOUR CHOSEN VICTIM HOWEVER YOU WANT. TEXT, EMAIL, IM, WHATEVER. WE RECOMMEND INCLUDING A PERSONAL MESSAGE TO MAKE IT EXTRA CREEPY. HAVE THEM WATCH THE VIDEO UNTIL THE VERY END.</p>
      </div>
      <div className='title step'>
        <p>STEP 3: CACKLE LIKE A WITCH, THEN INVITE YOUR VICTIM TO A&amp;W TO BURY THE HATCHET OVER SOME HOT, CRISPY ONION RINGS... <br></br>IF YOU BOTH SURVIVE THAT LONG.</p>
      </div >
      <div className='row nextVictimBtn'>
        <button className='btn' onClick={() => {history.push('/')}}>CHOOSE YOUR NEXT VICTIM</button>
      </div>
      <Footer />
    </div>
  );
}
