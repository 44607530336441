import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import OnionRing from '../assets/OnionRing.png';
import Footer from './Footer';
import '../App.css';

export default function ChooseVictim() {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState();

  const handleSubmit = (event) => {
    event.preventDefault();
    let phone = '';
    if (phoneNumber) {
      phone = phoneNumber.slice(2);
    }
    history.push(`/share/${phone}`);
  }

  return (
    <div className='app'>
      <img className='theOnionRing' src={OnionRing} alt='The Onion Ring' />
      <div className='title'>
        <p>THIS HALLOWEEN, HERE'S A TRICK TO GO WITH A&amp;W'S TREATS. FREAK OUT YOUR FRIENDS BY SENDING THEM THE ONION RING, A CURSED VIRAL VIDEO JUST LIKE IN THE MOVIE. WHEN THEY'RE DONE WATCHING IT THEY'LL GET A CREEPY PHONE CALL THAT'S GUARANTEED TO KEEP THEM UP AT NIGHT, OR AT LEAST MAKE THEM HUNGRY.</p>
      </div>
      <div className='subtitle'>
        <p>CHOOSE YOUR VICTIM AND ENTER THEIR PHONE NUMBER RIGHT HERE...</p>
      </div>
      <form onSubmit={handleSubmit}>
        <PhoneInput
          placeholder="Enter a phone number"
          className='inputBox phoneBox'
          country="US"
          value={phoneNumber}
          onChange={setPhoneNumber} />
        <input className='inputBox submitButton' type='submit' value='SUBMIT' />
      </form>
      <Footer />
    </div>
  );
}
