import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import ChooseVictim from './components/ChooseVictim';
import SpreadTheCurse from './components/SpreadTheCurse';
import PlayVideo from './components/PlayVideo';

export default function App () {
  const history = createBrowserHistory();

  // Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.initialize('G-6CYJ1FW9WL');
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  return (
    <Router>
        <Switch>
          <Route path='/share/:phoneNumber'>
            <SpreadTheCurse />
          </Route>
          <Route path='/:hexPhoneNumber'>
            <PlayVideo />
          </Route>
          <Route path='/'>
            <ChooseVictim />
          </Route>
        </Switch>
    </Router>
  );
}
