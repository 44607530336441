import React from 'react';
import '../App.css';
import AWLogo from '../assets/A_W.png';

export default function Footer() {
  return (
    <div className='app'>
      <div className='row footerLogo'>
        <p className='presentedBy'>PRESENTED BY</p>
        <a href='https://awrestaurants.com/'>
          <img className='AWLogo' src={AWLogo} alt='A&amp;W logo' />
        </a>
      </div>
      <div className='footer'>
        <p>DON'T BE SCARED OF OUR&nbsp;
          <a className='privacyLink' href='https://awrestaurants.com/legal'>PRIVACY POLICY</a>
        </p>
      </div>
    </div>
  );
}
